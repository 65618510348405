* {
  &:active,
  :focus {
    outline: none !important;  // 1
  }
}

a:not(.mat-button):not(.mat-raised-button):not(.mat-fab):not(.mat-mini-fab):not([mat-list-item]):not([mat-menu-item]):not([mat-flat-button]):not([mat-raised-button]) {
  color: theme-color("accent"); // 2
}
