.mat-toolbar {
  background: #ffffff;
  position: fixed;
  top: 0;
  z-index: 2;
  padding: 0!important;
}

.mat-toolbar button.mat-button-base .mat-button-wrapper {
  display: flex;
  align-items: center;
}

.mat-list-item {
  border-radius: 4px;

  .item-title {
    margin-left: 0.25rem;
  }

  &.active {
    background: map-get($theme-colors, primary);

    .item-title {
      color: #FFFFFF;
    }
  }

  &:hover {
    color: rgba(0, 0, 0, 0.87);
    background: #efefef;

    .item-title {
      color: initial;
    }
  }
}

// 升級 angular 後，不明原因，還測有時間找。
.mat-mdc-menu-panel {
  background-color: white !important;
}