@use 'sass:map';
@use '../core/theming/theming';
@use '../core/tokens/m2/mat/icon' as tokens-mat-icon;
@use '../core/tokens/token-utils';
@use '../core/style/sass-utils';

@mixin _palette-colors($config, $palette-name) {
  $palette: map.get($config, $palette-name);
  $color: theming.get-color-from-palette($palette, text);
  $tokens: tokens-mat-icon.private-get-icon-color-tokens($color);
  @include token-utils.create-token-values(tokens-mat-icon.$prefix, $tokens);
}

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);

  @include sass-utils.current-selector-or-root() {
    @include token-utils.create-token-values(tokens-mat-icon.$prefix,
      tokens-mat-icon.get-color-tokens($config));
  }

  .mat-icon {
    &.mat-primary {
      @include _palette-colors($config, primary);
    }

    &.mat-accent {
      @include _palette-colors($config, accent);
    }

    &.mat-warn {
      @include _palette-colors($config, warn);
    }
  }
}

@mixin typography($config-or-theme) {}

@mixin density($config-or-theme) {}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-icon') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}

