@use 'sass:map';
@use '../theming/theming';

// Renders a gradient for showing the dashed line when the input is disabled.
// Unlike using a border, a gradient allows us to adjust the spacing of the dotted line
// to match the Material Design spec.
@mixin private-control-disabled-underline($color) {
  background-image: linear-gradient(to right, $color 0%, $color 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

// Figures out the color of the placeholder for a form control.
// Used primarily to prevent the various form controls from
// becoming out of sync since these colors aren't in a palette.
@function private-control-placeholder-color($config) {
  $foreground: map.get($config, foreground);
  $is-dark-theme: map.get($config, is-dark);
  @return theming.get-color-from-palette($foreground, secondary-text,
    if($is-dark-theme, 0.5, 0.42));
}
