// Imports functions, variables, and mixins that are needed by other Bootstrap files v4.6
@use '@angular/material' as mat;
@import './themes/variables';
@import './themes/bootstrap/functions';
@import './themes/bootstrap/variables';
@import './themes/bootstrap/mixins';
// $theme-colors: map-remove($theme-colors, "info", "light", "dark");
@import './themes/bootstrap/reboot';
@import './themes/bootstrap/grid'; // add the grid
@import './themes/bootstrap/utilities';
@import './themes/reset';
@import './themes/bootstrap/breadcrumb';
@import './themes/bootstrap/badge';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

@import './themes/candy';
@include mat.all-legacy-component-themes($candy-app-theme);

@import './themes/custom/web';
@import './themes/custom/gadget';
@import './themes/custom/material';
@import './themes/custom/setting';

@import '@angular/cdk/overlay-prebuilt.css';

// 暫時沒用到了。
// @import "./node_modules/@ag-grid-community/core/dist/styles/ag-grid.scss";
// @import "./node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

// .ag-theme-alpine {
//   @include ag-theme-alpine();
// }

// src/app/school/gadget-host/gadget-proxy
.gadget-iframe {
  width: 100%;
  height: 100%;
  border-width: 0;
  border: none;
}

// 以高度為 70vh 為準，將項目放在中央。
.center-70vh {
  display: flex;
  width: 100%;
  height: 70vh;
  justify-content: center;
  align-items: center;
}

.my-mat-dialog-content .mat-dialog-container {
  overflow: hidden !important;
}
