@use 'sass:map';
@use '../core/theming/theming';

/// @deprecated Use `mat.progress-spinner-color` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
/// @breaking-change 17.0.0
@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);

  .mat-progress-spinner, .mat-spinner {
    circle {
      stroke: theming.get-color-from-palette($primary);
    }

    &.mat-accent circle {
      stroke: theming.get-color-from-palette($accent);
    }

    &.mat-warn circle {
      stroke: theming.get-color-from-palette($warn);
    }
  }
}

/// @deprecated Use `mat.progress-spinner-typography` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
/// @breaking-change 17.0.0
@mixin typography($config-or-theme) {}

@mixin _density($config-or-theme) {}

/// @deprecated Use `mat.progress-spinner-theme` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
/// @breaking-change 17.0.0
@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-legacy-progress-spinner') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include _density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
