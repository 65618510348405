@media (max-width: 991.98px) {
  .container {
    max-width: none;
    padding: 1rem 1rem;
  }
}

@media (min-width: 992px) {
  .container {
    padding: 1rem 1.5rem;
  }
}

svg[data-icon] {
  font-size: 24px;
}

.wrapper {
  margin-top: 64px;
}

.btn-nav-gray {
  font-size: 1rem;
  &:hover {
    color: rgba(0, 0, 0, 0.87);
    background: #efefef;
  }
}

.mdi {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}

/* ---------------------------------------------------
    navPersonalMenuPanel
----------------------------------------------------- */
.nav-personal-menu-panel {
  min-width: 320px !important;
  max-height: calc(100vh - 62px - 100px)!important;
  background-color: white;
  
  .mat-menu-content {
    padding: 0.5rem;
  }

  .mat-menu-item {
    font-size: 1rem;
    font-weight: 500!important;
    padding-left: 2rem;
  }

  .sub-header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(0 ,0 ,0, .54);
    display: block;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    height: 3rem;
    margin-top: 0;
    margin-bottom: 0;
    padding: 1rem 1rem;
  }

  .min-link {
    text-align: center;

    a {
      font-size: 0.85rem;
      color: rgba(0, 0, 0, 0.54)!important;
    }

    small {
      font-size: 50%;
      color: rgba(0,0,0,.54);
    }
  }

  .authInfo-header {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 1.25rem 1rem;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    font-weight: bolder;
    line-height: 1.5rem;
    margin: 0;
    white-space: nowrap;

    > img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    h1 {
      font-size: 1.25rem;
      margin: 0;
      font-weight: 500;
    }

    p {
      font-size: 0.85rem;
      margin-bottom: 0;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

/* ---------------------------------------------------
    navAccountMenuPanel
----------------------------------------------------- */
.nav-account-menu-panel {
  min-width: 320px !important;
  max-height: calc(100vh - 62px - 100px)!important;
  background-color: white;

  .mat-menu-content {
    padding: 0.5rem;
  }

  .mat-menu-item {
    font-size: 1rem;
    font-weight: 500!important;
    padding-left: 1.5rem;

    i {
      font-weight: 500!important;
      font-size: 1.4rem;
      margin-right: 0.5rem!important;
    }
  }

  .header {
    font-size: 1.125rem;
    padding: 0.5rem 1rem;
  }

  .sub-header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #323232;
    display: block;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.25rem 0.5rem;
  }

  .identity {
    .identity-card {
      padding: 0.5rem 1.25rem;
      height: 56px;
      cursor: pointer;

      &:hover {
        background: #F3F3F3;
        border-radius: 4px;
      }

      &.active {
        background: map-get($theme-colors, primary);
        border-radius: 4px;

        .role-title, .role-icon {
          color: #FFFFFF;
        }
      }

      .role-icon {
        font-size: 24px;
        max-width: 24px;
      }

      .role-title {
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.56rem;
        color: map-get($theme-colors, primary);
      }

      .sn {
        font-size: 0.875rem;
        font-weight: normal;
        line-height: 1.25rem;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

/* ---------------------------------------------------
    mode-nav
----------------------------------------------------- */

.mode-nav {
  width: 280px;
  min-width: 280px;

  .subtitle {
    font-size: 0.85rem;
    color: rgba(0, 0, 0, 0.6);
    padding: 1rem;
    margin: 0;
  }

  .list-group-item {
    box-shadow: none;
    border-radius: 8px;

    &.active,
    &.active i {
      color: #73831F;
      background: #f0f5dc;
    }

    i {
      width: 24px;
      font-size: 18px;
      text-align: center;
      padding: 4px 0;
      margin-right: 16px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

/* ---------------------------------------------------
    sm-item - Module Set
----------------------------------------------------- */

.sm-item {
  display: flex;
  align-items: center;
  border: 1px solid #E6E6E6;
  margin-bottom: 16px;
  margin-right: 8px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  width: calc((100% - 8px * 4) / 5);

  &:hover {
    border: 1px dashed map-get($theme-colors, primary);;

    span {
      border-left: 1px dashed map-get($theme-colors, primary);;
      color: map-get($theme-colors, primary);;
    }
  }

  span {
    background: #eee;
    height: 100%;
    padding: 1rem .5rem;
    border-left: 1px solid #E6E6E6;
    margin-left: auto;
    border-radius: 0 8px 8px 0;
    color: rgba(0, 0, 0, 0.54);
  }

  img {
    width: 44px;
    margin: 0 .5rem;
  }
}

@media (max-width: 767px) {
	.sm-item {
		width: 100%;
		margin-bottom: 8px;
	}
}

@media (min-width: 768px) and (max-width: 1279px) {
	.sm-item {
		width: calc((100% - 8px) / 2);
		margin-right: 8px;
    margin-bottom: 8px;

    &:nth-child(2n+2) {
      margin-right: 0;
    }
	}
}

@media (min-width: 1280px)and (max-width: 1440px) {
	.sm-item {
		width: calc((100% - 8px * 2) / 3);
		margin-right: 8px;
    margin-bottom: 8px;

    &:nth-child(3n+3) {
      margin-right: 0;
    }
	}
}

@media (min-width: 1441px) {
	.sm-item {
		width: calc((100% - 8px * 3) / 4);
		margin-right: 8px;
    margin-bottom: 8px;

    &:nth-child(4n+4) {
      margin-right: 0;
    }
	}
}


/* ---------------------------------------------------
   choseId , ID ITEM STYLE
----------------------------------------------------- */

#choseId {
  z-index: 999;

  .alert {
    color: #bd4147;
  }
}

.id-item {
  flex: 1;
  min-width: 120px;
  height: 225px;
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
  margin-bottom: 16px;
  margin-right: 8px;
  /* -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .16); */
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  border: 1px solid #E6E6E6;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 8px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: #f1f1f1;
  }
}

.id-item-icon {
  width: 100px;
  height: 170px;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.id-item-text {
  text-align: center;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  border-top: 1px solid #E6E6E6;
  height: 54px;
  padding: 16px 8px;
  font-weight: 600;
}

@media (max-width: 500px) {

  #id-list {
    margin-bottom: 16px;
  }

  .id-item {
    flex: auto;
    position: inherit;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: auto;
    text-align: left;
    padding: 8px 16px;
    margin-right: 0;
    margin-bottom: -1px;
    background: #fff;
    align-items: center;
  }

  .id-item-icon {
    width: 44px;
    height: 44px;
    background-size: cover !important;
    margin-right: 16px;
  }

  .id-item-text {
    background: none;
  }

  #id-parent {
    display: block;
    margin-bottom: 16px;
    padding: 0;
  }

  #id-parent .id-item-icon {
    height: auto;
  }

  #id-parent .id-item-text {
    background: #FAFAFA;
  }
}


/* ---------------------------------------------------
   candy-radio
----------------------------------------------------- */
.candy-radio {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  border-color: #323232;
  margin-right: 0.5rem;

  &.checked::before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    background-color: #323232;
    border-radius: 50%;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    transform: scale(0.5);
  }
}

/* ---------------------------------------------------
   toProfile
----------------------------------------------------- */
#toProfile .upload-img {
  position: relative;
  width: 100%;
  padding-top: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
}

#toProfile .upload-img > div {
	position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#toProfile .upload-img i {
	font-size: 3rem;
}

#toProfile .upload-img img {
	cursor: pointer;
	width: 100%;
	border-radius: 8px;
}

#toProfile .upload-img.uploaded::before {
	cursor: pointer;
	position: absolute;
	z-index: 2;
	top: calc(50% - 18px);
  left: calc(50% - 59px);
	content: '重新選擇圖片';
	border: 1px solid rgba(255,255,255,.8);
  border-radius: 3px;
  padding: 8px 16px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
}

/* ---------------------------------------------------
    Profile Media
----------------------------------------------------- */

@media (max-width: 959px) and (min-width: 414px){
	#toProfile .upload-img {
		width: 50%;
    padding-top: 50%;
	}
}
