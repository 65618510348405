/* ---------------------------------------------------
    GADGET ITEM STYLE
----------------------------------------------------- */
@mixin gadget-line-max-count($i) {
  width: calc((100% - 8px * #{$i - 1}) / #{$i});
}

@mixin gadget-item-child($n) {
  &:nth-child(#{$n'n+'$n}){
    margin-right: 0;
  }
}

.gadget-box {
  min-width: 120px;
  height: 156px;
  margin-bottom: 8px;
  margin-right: 8px;
}

.gadget-item {
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
  border: 1px solid #E6E6E6;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  height: inherit;
  position: relative;
  background-color: #ffffff;

  &:hover {
    border: 1px solid map-get($theme-colors, primary);
    border-radius: 8px;
  }

  .gadget-status {
    position: absolute;
    top: 0;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 36px;
    padding: 0 8px;
    color: rgba(0, 0, 0, .18);
    font-size: 24px;
    width: 100%;

    i {
      padding-left: 8px;

      &:first-child {
        padding-left: 0;
      }

      &:hover {
        color: #2196f3;
      }

      &.mdi-open-in-new:hover {
        color: rgba(0, 0, 0, .18);
      }
    }
  }

  .gadget-item-icon {
    width: 100px;
    height: 120px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain;
  }

  .gadget-item-text {
    text-align: center;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    height: 54px;
    padding: 0 8px;
    color: #323232;
  }
}

// 一般瀏覽頁面
.gadget-list .gadget-box {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@media (max-width: 575px) {
  .gadget-list .gadget-box {
    @include gadget-line-max-count(2);
    @include gadget-item-child(2)
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .gadget-list .gadget-box {
    @include gadget-line-max-count(3);
    @include gadget-item-child(3)
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .gadget-list .gadget-box {
    @include gadget-line-max-count(4);
    @include gadget-item-child(4)
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .gadget-list .gadget-box {
    @include gadget-line-max-count(5);
    @include gadget-item-child(5)
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .gadget-list .gadget-box {
    @include gadget-line-max-count(6);
    @include gadget-item-child(6)
  }
}

@media (min-width: 1401px) {
  .gadget-list .gadget-box {
    @include gadget-line-max-count(7);
    @include gadget-item-child(7)
  }
}


// 學校管理者用
@media (max-width: 992px) {
  .system-set .gadget-box {
    @include gadget-line-max-count(2);
    @include gadget-item-child(2)
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .system-set .gadget-box {
    @include gadget-line-max-count(3);
    @include gadget-item-child(3)
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .system-set .gadget-box {
    @include gadget-line-max-count(4);
    @include gadget-item-child(4)
  }
}

@media (min-width: 1401px) {
  .system-set .gadget-box {
    @include gadget-line-max-count(5);
    @include gadget-item-child(5)
  }
}

.cdk-drop-list-dragging > *:not(.cdk-drag-placeholder) {
  display: none;
}

.cdk-drop-list-dragging > .cdk-drag-placeholder .gadget-item {
  background: rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
