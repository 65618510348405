@use 'sass:map';
@use 'sass:math';
@use '../core/theming/theming';
@use '../core/typography/typography';
@use '../core/typography/typography-utils';


// Theme styles that only apply to the outline appearance of the form-field.

/// @deprecated Use `mat.form-field-outline-color` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
/// @breaking-change 17.0.0
@mixin outline-color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $foreground: map.get($config, foreground);
  $is-dark-theme: map.get($config, is-dark);

  $label-disabled-color: theming.get-color-from-palette($foreground, disabled-text);
  $outline-color:
    theming.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.3, 0.12));
  $outline-color-hover:
    theming.get-color-from-palette($foreground, divider, if($is-dark-theme, 1, 0.87));
  $outline-color-primary: theming.get-color-from-palette($primary);
  $outline-color-accent: theming.get-color-from-palette($accent);
  $outline-color-warn: theming.get-color-from-palette($warn);
  $outline-color-disabled:
    theming.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.15, 0.06));

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: $outline-color;
    }

    .mat-form-field-outline-thick {
      color: $outline-color-hover;
    }

    &.mat-focused {
      .mat-form-field-outline-thick {
        color: $outline-color-primary;
      }

      &.mat-accent .mat-form-field-outline-thick {
        color: $outline-color-accent;
      }

      &.mat-warn .mat-form-field-outline-thick {
        color: $outline-color-warn;
      }
    }

    // Class repeated so that rule is specific enough to override focused accent color case.
    &.mat-form-field-invalid.mat-form-field-invalid {
      .mat-form-field-outline-thick {
        color: $outline-color-warn;
      }
    }

    &.mat-form-field-disabled {
      .mat-form-field-label {
        color: $label-disabled-color;
      }

      .mat-form-field-outline {
        color: $outline-color-disabled;
      }
    }
  }
}

// Used to make instances of the _mat-form-field-label-floating mixin negligibly different,
// and prevent Google's CSS Optimizer from collapsing the declarations. This is needed because some
// of the selectors contain pseudo-classes not recognized in all browsers. If a browser encounters
// an unknown pseudo-class it will discard the entire rule set.
$outline-dedupe: 0;

// Applies a floating label above the form field control itself.
@mixin _label-floating($font-scale, $infix-padding, $infix-margin-top) {
  transform: translateY(-$infix-margin-top - $infix-padding + $outline-dedupe)
  scale($font-scale);
  width: math.div(100%, $font-scale) + $outline-dedupe;

  $outline-dedupe: $outline-dedupe + 0.00001 !global;
}

/// @deprecated Use `mat.form-field-outline-typography` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
/// @breaking-change 17.0.0
@mixin outline-typography($config-or-theme) {
  $config: typography.private-typography-to-2014-config(
      theming.get-typography-config($config-or-theme));
  // The unit-less line-height from the font config.
  $line-height: typography-utils.line-height($config, input);
  // The amount to scale the font for the floating label and subscript.
  $subscript-font-scale: 0.75;
  // The padding above and below the infix.
  $infix-padding: 1em;
  // The margin applied to the form-field-infix to reserve space for the floating label.
  $infix-margin-top:
      $subscript-font-scale * typography-utils.private-coerce-unitless-to-em($line-height);
  // The space between the bottom of the .mat-form-field-flex area and the subscript wrapper.
  // Mocks show half of the text size, but this margin is applied to an element with the subscript
  // text font size, so we need to divide by the scale factor to make it half of the original text
  // size.
  $subscript-margin-top: math.div(0.5em, $subscript-font-scale);
  // The padding applied to the form-field-wrapper to reserve space for the subscript, since it's
  // absolutely positioned. This is a combination of the subscript's margin and line-height, but we
  // need to multiply by the subscript font scale factor since the wrapper has a larger font size.
  $wrapper-padding-bottom: ($subscript-margin-top + $line-height) * $subscript-font-scale;
  // The amount we offset the label from the input text in the outline appearance.
  $outline-appearance-label-offset: -0.25em;

  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: $infix-padding 0 $infix-padding 0;
    }

    .mat-form-field-label {
      top: $infix-margin-top + $infix-padding;
      margin-top: $outline-appearance-label-offset;
    }

    &.mat-form-field-can-float {
      &.mat-form-field-should-float .mat-form-field-label,
      .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
        @include _label-floating(
                $subscript-font-scale, $infix-padding + $outline-appearance-label-offset,
                $infix-margin-top);
      }

      // Server-side rendered matInput with a label attribute but label not shown
      // (used as a pure CSS stand-in for mat-form-field-should-float).
      .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
        @include _label-floating(
                $subscript-font-scale, $infix-padding + $outline-appearance-label-offset,
                $infix-margin-top);
      }
    }
  }
}

/// @deprecated Use `mat.form-field-private-form-field-outline-density` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
/// @breaking-change 17.0.0
@mixin private-form-field-outline-density($config-or-theme) {}

/// @deprecated Use `mat.form-field-outline-theme` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
/// @breaking-change 17.0.0
@mixin outline-theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-form-field-outline') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include outline-color($color);
    }
    @if $density != null {
      @include private-form-field-outline-density($density);
    }
    @if $typography != null {
      @include outline-typography($typography);
    }
  }
}

