$theme-colors: (
  "primary": #5EC1C7,
  "secondary": #547CB4,
  "danger": #E33954
);

$body-color: #323232;
$border-color: #A3A3A3;
$link-color: map-get($theme-colors, secondary) !default;
$link-hover-color: currentColor;
$link-hover-decoration: none;
$label-margin-bottom: 0;

$grid-breakpoints: (
  xs: 0, // handset portrait (small, medium, large) | handset landscape (small)
  sm: 576px, // handset landscape (medium, large) | tablet portrait(small, large)
  md: 768px, //  tablet landscape (small, large)
  lg: 992px, // laptops and desktops
  xl: 1200px, // large desktops
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px
);

$enable-gradients: true;
