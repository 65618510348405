@use '@angular/material' as mat;
$mat-candy-mint: (
  50: #E2F6F6,
  100: #B9EAEA,
  200: #90DCDD,
  300: #6ECCD0,
  400: #6ECCD0,
  500: #5EC1C7,
  600: #5EC1C7,
  700: #1098A0,
  800: #108489,
  900: #0E615F,
  A100:#B9EAEA,
  A200:#90DCDD,
  A400: #6ECCD0,
  A700: #1098A0,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: rgba(white, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-candy-yellow: (
  50: #FCF6DF,
  100: #F7E6AE,
  200: #F1D67A,
  300: #ECC743,
  400: #E9BB13,
  500: #E6B000,
  600: #E6A200,
  700: #E79000,
  800: #EE6800,
  900: #E66000,
  A100: #F7E6AE,
  A200: #F1D67A,
  A400: #E9BB13,
  A700: #E79000,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: rgba(white, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-candy-blue: (
  50: #E7F2FA,
  100: #C5DFF3,
  200: #A4CCEB,
  300: #87B8E1,
  400: #74A9DA,
  500: #679BD4,
  600: #5E8DC7,
  700: #547CB4,
  800: #4B6BA2,
  900: #3C4F81,
  A100: #C5DFF3,
  A200: #A4CCEB,
  A400: #74A9DA,
  A700: #547CB4,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: rgba(white, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-candy-green: (
  50: #F1F8E9,
  100: #DCEDC8,
  200: #C5E1A5,
  300: #AED581,
  400: #9CCC65,
  500: #8BC34A,
  600: #7CB342,
  700: #689F38,
  800: #558B2F,
  900: #33691E,
  A100: #DCEDC8,
  A200: #C5E1A5,
  A400: #9CCC65,
  A700: #689F38,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: rgba(white, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-candy-red: (
  50: #FCE5EA,
  100: #F9BDCA,
  200: #F493A7,
  300: #EE6985,
  400: #E84D6C,
  500: #E33954,
  600: #D33453,
  700: #BD2F4F,
  800: #A9294C,
  900: #852145,
  A100: #F9BDCA,
  A200: #F493A7,
  A400: #E84D6C,
  A700: #BD2F4F,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: rgba(white, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-candy-gray: (
  50: #F7F7F7,
  100: #EEEEEE,
  200: #323232,
  300: #D0D0D0,
  400: #ABABAB,
  500: #8A8A8A,
  600: #636363,
  700: #505050,
  800: #323232,
  900: #121212,
  A100: #EEEEEE,
  A200: #323232,
  A400: #ABABAB,
  A700: #505050,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: rgba(white, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);

$candy-app-primary: mat.define-palette($mat-candy-mint, 500);
$candy-app-accent: mat.define-palette($mat-candy-yellow, 700);
$candy-app-warn: mat.define-palette($mat-candy-red, 500);

// mat-typography-level: font-size, line-height, and font-weight.
$candy-font-size-base: 1rem;
$convert-size: 0.0625;

$candy-app-typography: mat.define-legacy-typography-config(
  $font-family:   'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  $display-4:     mat.define-typography-level(112 * $candy-font-size-base * $convert-size, 112 * $candy-font-size-base * $convert-size, 300, $letter-spacing: -0.05em),
  $display-3:     mat.define-typography-level(56 * $candy-font-size-base * $convert-size, 56 * $candy-font-size-base * $convert-size, 400, $letter-spacing: -0.02em),
  $display-2:     mat.define-typography-level(45 * $candy-font-size-base * $convert-size, 48 * $candy-font-size-base * $convert-size, 400, $letter-spacing: -0.005em),
  $display-1:     mat.define-typography-level(34 * $candy-font-size-base * $convert-size, 40 * $candy-font-size-base * $convert-size, 400),
  $headline:      mat.define-typography-level(24 * $candy-font-size-base * $convert-size, 32 * $candy-font-size-base * $convert-size, 400),
  $title:         mat.define-typography-level(20 * $candy-font-size-base * $convert-size, 32 * $candy-font-size-base * $convert-size, 500),
  $subheading-2:  mat.define-typography-level(16 * $candy-font-size-base * $convert-size, 28 * $candy-font-size-base * $convert-size, 400),
  $subheading-1:  mat.define-typography-level(15 * $candy-font-size-base * $convert-size, 24 * $candy-font-size-base * $convert-size, 400),
  $body-2:        mat.define-typography-level(14 * $candy-font-size-base * $convert-size, 24 * $candy-font-size-base * $convert-size, 500),
  $body-1:        mat.define-typography-level(14 * $candy-font-size-base * $convert-size, 20 * $candy-font-size-base * $convert-size, 400),
  $caption:       mat.define-typography-level(12 * $candy-font-size-base * $convert-size, 20 * $candy-font-size-base * $convert-size, 400),
  $button:        mat.define-typography-level(14 * $candy-font-size-base * $convert-size, 14 * $candy-font-size-base * $convert-size, 500),
  // Line-height must be unit-less fraction of the font-size.
  $input:         mat.define-typography-level(inherit, 1.125, 400)
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$candy-app-theme: mat.define-light-theme((
  color: (
    primary: $candy-app-primary,
    accent: $candy-app-accent,
    warn: $candy-app-warn,
  )
));

/* 建立深色主題(目前沒有用到的樣子) */
// $candy-theme: mat.define-dark-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
