/* ---------------------------------------------------
    Identity
----------------------------------------------------- */

.m-identity {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.m-identity .area {
	padding: 16px;
	border: 1px solid rgba(0,0,0,.12);
	display: -ms-flexbox;
	display: flex;
	color: rgba(0,0,0,.54);
	border-radius: 4px;
}

.m-identity .area img {
	width: 40px;
    height: 40px;
	border-radius: 50%;
}

.m-identity .area > div {
	width: 100%;
	margin: 0 16px;
}

.m-identity .area .name {
	font-size: 1rem;
	font-weight: 500;
	color: rgba(0,0,0,.87);
}

.area {
	border-radius: 8px;
}

@media (max-width: 767px) {
	.area {
		width: 100%;
		margin-bottom: 8px;
	}
}

@media (min-width: 768px) and (max-width: 1279px) {
	.area {
		width: calc((100% - 8px) / 2);
		margin-right: 8px;
		margin-bottom: 8px;
	}

	.area:nth-child(2n+2) {
		margin-right: 0;
	}
}

@media (min-width: 1280px)and (max-width: 1440px) {
	.area {
		width: calc((100% - 8px * 2) / 3);
		margin-right: 8px;
		margin-bottom: 8px;
	}

	.area:nth-child(3n+3) {
		margin-right: 0;
	}
}

@media (min-width: 1441px) {
	.area {
		width: calc((100% - 8px * 3) / 4);
		margin-right: 8px;
		margin-bottom: 8px;
	}

	.area:nth-child(4n+4) {
		margin-right: 0;
	}
}
