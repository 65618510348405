@use 'sass:map';
@use '../../core/theming/theming';
@use '../../core/typography/typography';
@use '../../core/typography/typography-utils';

/// @deprecated Use `mat.optgroup-color` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
/// @breaking-change 17.0.0
@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);

  .mat-optgroup-label {
    color: theming.get-color-from-palette($foreground, secondary-text);
  }

  .mat-optgroup-disabled .mat-optgroup-label {
    color: theming.get-color-from-palette($foreground, hint-text);
  }
}

/// @deprecated Use `mat.optgroup-typography` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
/// @breaking-change 17.0.0
@mixin typography($config-or-theme) {
  $config: typography.private-typography-to-2014-config(
      theming.get-typography-config($config-or-theme));
  .mat-optgroup-label {
    @include typography-utils.typography-level($config, body-2);
  }
}

@mixin _density($config-or-theme) {}

/// @deprecated Use `mat.optgroup-theme` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
/// @breaking-change 17.0.0
@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-legacy-optgroup') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include _density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
